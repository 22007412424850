import React, { Component } from "react";

//Constants
import { API_WEB_URLS } from "../../constants/constAPI";

//Store
import { Fn_FillListData, Fn_GetReport } from "../../store/functions";

import { Container, Row, Col, Card, CardBody, Button, Modal } from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Masters/datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as XLSX from "xlsx";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      formData: {
        ViewType: 1,
      },
      success_msg: false,
      dynamic_title: "",
      dynamic_description: "",

      F_CollegeMaster: 0,
      ViewType: 0,
      DateAsOn: "",
      page: 1,
      sizePerPage: 10,
      productDataLia: [],
      header_dd: [],
      success_dlg: false,
      rows: [
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
      ],
      cols: [],
     
      mirenE: [{}],
      mirenG : [{}],
      jyoti: [{}]
      
    };
    this.obj = this;
    this.breadCrumbTitle = "Reports";
    this.breadCrumbItem = "Dashboard";
    this.modalTitle = "Dashboard";
    this.rtPage_Add = "add-BalanceSheet";
    this.rtPage_Edit = "edit-BalanceSheet";
    
    this.API_URL_SAVE = "BalanceSheet1/0/token";
    this.API_URL_SAVE_Assest = "BalanceSheet1/0/token";


    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.syno = this.syno.bind(this);
    
  }

  componentDidMount() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    this.setState({
      name: obj.username,
      email: obj.email,
      aid: obj.uid,
      role: obj.role,
    });

    let vformData = new FormData();
    vformData.append("Id", 4);
    vformData.append("From", '2000-01-01');
    vformData.append("To", getCurrentDate());


    let vformData2 = new FormData();
    vformData2.append("Id", 6);
    vformData2.append("From", '2000-01-01');
    vformData2.append("To", getCurrentDate());

    let vformData3 = new FormData();
    vformData3.append("Id", 7);
    vformData3.append("From", '2000-01-01');
    vformData3.append("To", getCurrentDate());

Fn_GetReport(
        this.obj,
        { arguList: { id: 0, formData: vformData } },
        "GetLedgersBalanceSheet/0/token",
        "mirenE",
        true
      );

      Fn_GetReport(
        this.obj,
        { arguList: { id: 0, formData: vformData2 } },
        "GetLedgersBalanceSheet/0/token",
        "jyoti",
        true
      );

      Fn_GetReport(
        this.obj,
        { arguList: { id: 0, formData: vformData3 } },
        "GetLedgersBalanceSheet/0/token",
        "mirenG",
        true
      );

      
  }
  syno() {
    this.setState({ success_msg: false, success_dlg: false });
  }


  btnSave_onClick(event, formData) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    

    

    
      // Fn_GetReport(
      //   this.obj,
      //   { arguList: { id: 0, formData: vformData2 } },
      //   this.API_URL_SAVE_Assest2,
      //   "productDataAssest",
      //   true
      // );
    
  }



  render() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));


    const getLastTwoDecimalDigits = (value) => {
      const [integerPart, decimalPart] = value.toString().split('.');
      if (!decimalPart) return integerPart; // No decimal part
      return `${integerPart}.${decimalPart.slice(0, 2)}`;
    };
    
    
    const mirenGSum = getLastTwoDecimalDigits(
      this.state.mirenG.reduce((total, row) => total + row.Amount, 0)
    );

    const mirenESum = getLastTwoDecimalDigits(
      this.state.mirenE.reduce((total, row) => total + row.Amount, 0)
    );

    const jyotiSum = getLastTwoDecimalDigits(
      this.state.jyoti.reduce((total, row) => total + row.Amount, 0)
    );
    

    const columnsMirenG = [
      {
        dataField: "Name",
        text: "Name",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "Amount",
        text: "ClosingBalance",
        sort: true,
        footer: columnData => <div>{mirenGSum}</div>,
      }
      ];


      const columnsMirenE = [
        {
          dataField: "Name",
          text: "Name",
          sort: true,
          footer: columnData => <div></div>,
        },
        {
          dataField: "Amount",
          text: "ClosingBalance",
          sort: true,
          footer: columnData => <div>{mirenESum}</div>,
        }
        ];


        const columnsJyoti = [
          {
            dataField: "Name",
            text: "Name",
            sort: true,
            footer: columnData => <div></div>,
          },
          {
            dataField: "Amount",
            text: "ClosingBalance",
            sort: true,
            footer: columnData => <div>{jyotiSum}</div>,
          }
          ];

          
  
  

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 200,
      totalSize: this.state.productDataLia.length, // replace later with size(customers),
      custom: true,
    };

    // const pageOptionsCr = {
    //   sizePerPage: 10,
    //   totalSize: this.state.productDataLia.length, // replace later with size(customers),
    //   custom: true,
    // }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: this.state.productDataLia.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;
    const { selectedMulti } = this.state;
    return (
      <>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
         

              <Row>
                <Col lg='6'>
                  <h2>Payments</h2>
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory({
                          ...pageOptions,
                          sizePerPageList: [
                            { text: "25", value: 25 },
                            { text: "50", value: 50 },
                            { text: "100", value: 100 },
                          ],
                        })}
                        keyField="id"
                        columns={columnsMirenE}
                        data={this.state.mirenE}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columnsMirenE}
                            data={this.state.mirenE}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>

               <Col lg='6'>
                <Row>
                <Col className="col-12">
                  <h2>JYOTI</h2>
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory({
                          ...pageOptions,
                          sizePerPageList: [
                            { text: "25", value: 25 },
                            { text: "50", value: 50 },
                            { text: "100", value: 100 },
                          ],
                        })}
                        keyField="id"
                        columns={columnsJyoti}
                        data={this.state.jyoti}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columnsJyoti}
                            data={this.state.jyoti}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>

                
                <Col className="col-12">
                  <h2>MIREN GROUP</h2>
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory({
                          ...pageOptions,
                          sizePerPageList: [
                            { text: "25", value: 25 },
                            { text: "50", value: 50 },
                            { text: "100", value: 100 },
                          ],
                        })}
                        keyField="id"
                        columns={columnsMirenG}
                        data={this.state.mirenG}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columnsMirenG}
                            data={this.state.mirenG}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
                </Row>
              
               </Col>
              </Row>

          

          

      
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(Dashboard);
