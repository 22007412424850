import React, { Component } from "react";

//Constants
import { API_WEB_URLS } from "../../constants/constAPI";

//Store
import { Fn_FillListData, Fn_GetReport } from "../../store/functions";

import { Container, Row, Col, Card, CardBody, Button , Modal} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Masters/datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as XLSX from 'xlsx';

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
class reports_PAndL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      formData: {
        ViewType : 1,
        
      },
      success_msg: false,
      dynamic_title: "",
      dynamic_description: "",
    
      F_CollegeMaster: 0,
      ViewType: 0,
      DateAsOn: "",
      page: 1,
      sizePerPage: 10,
      productDataExpenses: [{}],
      header_dd: [],
      success_dlg: false,
      rows: [
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
      ],
      cols: [],
      F_Type: 0,
      selectedMulti: [],
      productDataIncome: [{}],
      openledgergroups : false,
      legdgergroupsdata : [{}],
      opensubledgergroups : false,
      sublegdgergroupsdata : [{}],
      openledgers : false,
      ledgersdata : [{}]
      
    };
    this.obj = this;
    this.breadCrumbTitle = "Reports";
    this.breadCrumbItem = "Profit & Loss";
    this.modalTitle = "Profit & Loss";
    this.rtPage_Add = "add-BalanceSheet";
    this.rtPage_Edit = "edit-BalanceSheet";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/BalanceSheet/Id/0";
    this.API_URL_SAVE =  "BalanceSheet1/0/token";
    this.API_URL_SAVE_Assest =  "BalanceSheet1/0/token";

    this.API_URL_SAVE2 =  "BalanceSheet2/0/token";
    this.API_URL_SAVE_Assest2 =  "BalanceSheet2/0/token";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.syno = this.syno.bind(this);
    this.exportToExcel  = this.exportToExcel.bind(this);
   
    
  }

  componentDidMount() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    this.setState({
      name: obj.username,
      email: obj.email,
      aid: obj.uid,
      role: obj.role,
    });

   
  }
  syno() {
    this.setState({ success_msg: false, success_dlg: false });
  }





  exportToExcel = () => {

    // let vformData = new FormData();
    
    // vformData.append("FromDate", this.state.FromDate);
    // vformData.append("ToDate",  this.state.ToDate);
    
    // Fn_ExportExcel(this.obj, { arguList: { id: 0, formData: vformData } }, "DMRExcel/0/token", "DMRTransaction", true);
    
const obj = JSON.parse(sessionStorage.getItem("authUser"));


    const worksheet = XLSX.utils.json_to_sheet(this.state.productDataExpenses);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'PAndLExpenses');
    XLSX.writeFile(workbook, `PAndLExpenses.xlsx`);

    const worksheet2 = XLSX.utils.json_to_sheet(this.state.productDataIncome);
    const workbook2 = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook2, worksheet2, 'PAndLIncome');
    XLSX.writeFile(workbook2, `PAndLIncome.xlsx`);


  };




  btnSave_onClick(event, formData) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    this.setState({ FromDate: formData.FromDate });

    let vformData = new FormData();
    
    // vformData.append("DateAsOn", formData.FromDate);
 
    vformData.append("Type", 1);
    vformData.append("From", formData.FromDate);
    vformData.append("To", formData.ToDate);

    let vformData2 = new FormData();
    
    // vformData.append("DateAsOn", formData.FromDate);
 
    vformData2.append("Type", 2);
    vformData2.append("From", formData.FromDate);
    vformData2.append("To", formData.ToDate);

    

    

    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      "PAndLExpenses/0/token",
      "productDataExpenses",
      true
    );
    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData2 } },
      "PAndLIncome/0/token",
      "productDataIncome",
      true
    );

   
  }

  handleMulti = selectedMulti => {
    this.setState({ selectedMulti });
    console.log(JSON.stringify(selectedMulti));
    var multiData = JSON.stringify(selectedMulti);
  };

  render() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    let columnNames = this.state.rows[0];


    const sumAmountExp = this.state.productDataExpenses.reduce((total, row) => total + row.Amount, 0);

    const sumAmountIncome = this.state.productDataIncome.reduce((total, row) => total + row.Amount, 0);
    // Assuming the first row has column names
    const columns = [
    {
      dataField: 'Name',
      text: 'Name',
      sort: true,
      footer: columnData => (
        <div>
         
        </div>
      )
    },
     {
      dataField: 'Amount',
      text: 'Amount',
      sort: true,
      footer: columnData => (
        <div>
          {sumAmountExp}
        </div>
      )
    }
  ];




  const columns1 = [
    {
      dataField: 'Name',
      text: 'Name',
      sort: true,
      footer: columnData => (
        <div>
         
        </div>
      )
    },
     {
      dataField: 'Amount',
      text: 'Amount',
      sort: true,
      footer: columnData => (
        <div>
          {sumAmountIncome}
        </div>
      )
    }
  ];








    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.productDataExpenses.length, // replace later with size(customers),
      custom: true,
    };

    // const pageOptionsCr = {
    //   sizePerPage: 10,
    //   totalSize: this.state.productDataLia.length, // replace later with size(customers),
    //   custom: true,
    // }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "100", value: 100 },
      { text: "200", value: 200 },
      { text: "300", value: 300 },
      { text: "400", value: 400 },
      { text: "500", value: 500 },
      { text: "All", value: this.state.productDataExpenses.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };
    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const { SearchBar } = Search;
    const { selectedMulti } = this.state;
    return (
      <>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
              <Row>
                <Col lg="12">
                  <Card >
                    <CardBody>
                     
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.btnSave_onClick}
                          >
                            <Row>
                              
                             

                              <Col sm="1" className="mb-3">
                                <label
                                  htmlFor="tilldatee"
                                  className="col-form-label"
                                >
                                  {" "}
                                  From Date
                                </label>
                              </Col>
                              <Col sm="3" className="mb-3">
                                <AvField
                                  name="FromDate"
                                  label=""
                                  value={
                                    this.state.formData.FromDate == undefined
                                      ? getCurrentDate()
                                      : this.state.formData.FromDate
                                  }
                                  placeholder="From Date"
                                  errorMessage="Select Date "
                                  validate={{ required: { value: true } }}
                                  type="date"
                                  className="form-control"
                                />
                              </Col>


                               <Col sm="1" className="mb-3">
                               <label
                                 htmlFor="tilldatee"
                                 className="col-form-label"
                               >
                                 {" "}
                                 To Date
                               </label>
                             </Col>
                             <Col sm="3" className="mb-3">
                               <AvField
                                 name="ToDate"
                                 label=""
                                 value={
                                   this.state.formData.FromDate == undefined
                                     ? getCurrentDate()
                                     : this.state.formData.FromDate
                                 }
                                 placeholder="To Date"
                                 errorMessage="Select Date "
                                 validate={{ required: { value: true } }}
                                 type="date"
                                 className="form-control"
                               />
                             </Col>

                              <Col sm="3" className="mb-3">
                                <Button
                                  type="submit"
                                  color="info"
                                  className="mr-1 waves-effect waves-light"
                                >
                                  {" "}
                                  View{" "}
                                </Button>
                                {" "}
                                 <Button
                                  type="Button"
                                  onClick={this.exportToExcel}
                                  color="danger"
                                  className="mr-1 waves-effect waves-light"
                                >
                                  {" "}
                                  Excel{" "}
                                </Button>
                              </Col>
                            </Row>
                          </AvForm>
                        
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-6">
                  <h2>Expenses</h2>
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory({
                          ...pageOptions,
                          sizePerPageList: [
                            { text: '25', value: 25 },
                            { text: '50', value: 50 },
                            { text: '100', value: 100 },
                          ],
                        })}
                        keyField="id"
                        columns={columns}
                        data={this.state.productDataExpenses}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={this.state.productDataExpenses}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-6">
                <h2>Income</h2>
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory({
                          ...pageOptions,
                          sizePerPageList: [
                            { text: '25', value: 25 },
                            { text: '50', value: 50 },
                            { text: '100', value: 100 },
                          ],
                        })}
                        keyField="id"
                        columns={columns1}
                        data={this.state.productDataIncome}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns1}
                            data={this.state.productDataIncome}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>



<Row>

 <h3> Net Profit -  {sumAmountIncome-sumAmountExp} </h3>
          
  </Row>


                  

         





         


           
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(reports_PAndL);
