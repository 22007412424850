import React, { Component } from "react";

//Constants
import { API_WEB_URLS } from "../../constants/constAPI";

//Store
import { Fn_FillListData, Fn_GetReport } from "../../store/functions";

import { Container, Row, Col, Card, CardBody, Button, Modal } from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Masters/datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as XLSX from "xlsx";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
class reports_BalanceSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      formData: {
        ViewType: 1,
      },
      success_msg: false,
      dynamic_title: "",
      dynamic_description: "",

      F_CollegeMaster: 0,
      ViewType: 0,
      DateAsOn: "",
      page: 1,
      sizePerPage: 10,
      productDataLia: [],
      header_dd: [],
      success_dlg: false,
      rows: [
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
      ],
      cols: [],
      F_Type: 0,
      selectedMulti: [],
      productDataAssest: [],
      openledgergroups: false,
      legdgergroupsdata: [{}],
      opensubledgergroups: false,
      sublegdgergroupsdata: [{}],
      openledgers: false,
      ledgersdata: [{}],
    };
    this.obj = this;
    this.breadCrumbTitle = "Reports";
    this.breadCrumbItem = "Balance Sheet";
    this.modalTitle = "Balance Sheet";
    this.rtPage_Add = "add-BalanceSheet";
    this.rtPage_Edit = "edit-BalanceSheet";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/BalanceSheet/Id/0";
    this.API_URL_SAVE = "BalanceSheet1/0/token";
    this.API_URL_SAVE_Assest = "BalanceSheet1/0/token";

    this.API_URL_SAVE2 = "BalanceSheet2/0/token";
    this.API_URL_SAVE_Assest2 = "BalanceSheet2/0/token";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.syno = this.syno.bind(this);
    this.getledgergroupsdata = this.getledgergroupsdata.bind(this);
    this.getsubledgergroupsdata = this.getsubledgergroupsdata.bind(this);
    this.exportToExcel = this.exportToExcel.bind(this);
  }

  componentDidMount() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    this.setState({
      name: obj.username,
      email: obj.email,
      aid: obj.uid,
      role: obj.role,
    });
  }
  syno() {
    this.setState({ success_msg: false, success_dlg: false });
  }

  exportToExcel = Id => {
    if (Id == 1) {
      const worksheet = XLSX.utils.json_to_sheet(this.state.legdgergroupsdata);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "MainLedgerGroups");
      XLSX.writeFile(workbook, `MainLedgerGroups.xlsx`);
    } else if (Id == 2) {
      const worksheet = XLSX.utils.json_to_sheet(
        this.state.sublegdgergroupsdata
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "sublegdgergroupsdata");
      XLSX.writeFile(workbook, `LedgerGroupsData.xlsx`);
    } else if (Id == 3) {
      const worksheet = XLSX.utils.json_to_sheet(this.state.ledgersdata);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "ledgersdata");
      XLSX.writeFile(workbook, `ledgersdata.xlsx`);
    }
  };

  getledgers = async (Id, Name) => {
    this.setState({ SubGroupId: Id, SubGroupName: Name });
    let vformData = new FormData();

    // vformData.append("DateAsOn", formData.FromDate);

    vformData.append("Id", Id);
    vformData.append("From", this.state.FromDate);
    vformData.append("To", this.state.ToDate);

    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      "GetLedgersBalanceSheet/0/token",
      "ledgersdata",
      true
    );

    this.setState({ openledgers: true });
  };

  getledgergroupsdata = async (Id, Name) => {
    this.setState({ BalansheetId: Id, BalanceSheetName: Name });
    let vformData = new FormData();

    // vformData.append("DateAsOn", formData.FromDate);

    vformData.append("Id", Id);
    vformData.append("From", this.state.FromDate);
    vformData.append("To", this.state.ToDate);

    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      "GetLedgerGroupsData/0/token",
      "legdgergroupsdata",
      true
    );

    this.setState({ openledgergroups: true });
  };

  getsubledgergroupsdata = async (Id, Name) => {
    this.setState({ LedgerGroupId: Id, LedgerGroupName: Name });
    let vformData = new FormData();

    // vformData.append("DateAsOn", formData.FromDate);

    vformData.append("Id", Id);
    vformData.append("From", this.state.FromDate);
    vformData.append("To", this.state.ToDate);

    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      "GetSubLedgersData/0/token",
      "sublegdgergroupsdata",
      true
    );

    this.setState({ opensubledgergroups: true });
  };

  btnSave_onClick(event, formData) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    this.setState({ FromDate: formData.FromDate, ToDate: formData.ToDate });

    let vformData = new FormData();

    // vformData.append("DateAsOn", formData.FromDate);

    vformData.append("Type", 1);
    vformData.append("From", formData.FromDate);
    vformData.append("To", formData.ToDate);

    let vformData2 = new FormData();

    // vformData.append("DateAsOn", formData.FromDate);

    vformData2.append("Type", 2);
    vformData2.append("From", formData.FromDate);
    vformData2.append("To", formData.ToDate);

    if (formData.ViewType == 1) {
      Fn_GetReport(
        this.obj,
        { arguList: { id: 0, formData: vformData } },
        this.API_URL_SAVE,
        "productDataLia",
        true
      );
      Fn_GetReport(
        this.obj,
        { arguList: { id: 0, formData: vformData2 } },
        this.API_URL_SAVE_Assest,
        "productDataAssest",
        true
      );
    } else {
      Fn_GetReport(
        this.obj,
        { arguList: { id: 0, formData: vformData } },
        this.API_URL_SAVE2,
        "productDataLia",
        true
      );
      Fn_GetReport(
        this.obj,
        { arguList: { id: 0, formData: vformData2 } },
        this.API_URL_SAVE_Assest2,
        "productDataAssest",
        true
      );
    }
  }

  handleMulti = selectedMulti => {
    this.setState({ selectedMulti });
    console.log(JSON.stringify(selectedMulti));
    var multiData = JSON.stringify(selectedMulti);
  };

  render() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    let columnNames = this.state.rows[0];
    
    // Utility function to get the last 2 digits of the decimal part
    const getLastTwoDecimalDigits = (value) => {
      const [integerPart, decimalPart] = value.toString().split('.');
      if (!decimalPart) return integerPart; // No decimal part
      return `${integerPart}.${decimalPart.slice(0, 2)}`;
    };
    
    // Calculations and formatting
    const MaingroupsdataLiaCr = getLastTwoDecimalDigits(
      this.state.productDataLia.reduce((total, row) => total + row.CrAmount, 0)
    );
    const MaingroupsdataLiaDr = getLastTwoDecimalDigits(
      this.state.productDataLia.reduce((total, row) => total + row.DrAmount, 0)
    );
    const MaingroupsdataLiaClosing = getLastTwoDecimalDigits(
      this.state.productDataLia.reduce((total, row) => total + row.Amount, 0)
    );
    
    const MaingroupsdataAssr = getLastTwoDecimalDigits(
      this.state.productDataAssest.reduce((total, row) => total + row.CrAmount, 0)
    );
    const MaingroupsdataAssDr = getLastTwoDecimalDigits(
      this.state.productDataAssest.reduce((total, row) => total + row.DrAmount, 0)
    );
    const MaingroupsdataAssClosing = getLastTwoDecimalDigits(
      this.state.productDataAssest.reduce((total, row) => total + row.Amount, 0)
    );
    
    const legdgergroupsdataCr = getLastTwoDecimalDigits(
      this.state.legdgergroupsdata.reduce((total, row) => total + row.CrAmount, 0)
    );
    const legdgergroupsdataDr = getLastTwoDecimalDigits(
      this.state.legdgergroupsdata.reduce((total, row) => total + row.DrAmount, 0)
    );
    const legdgergroupsdataClosing = getLastTwoDecimalDigits(
      this.state.legdgergroupsdata.reduce((total, row) => total + row.Amount, 0)
    );
    
    const sublegdgergroupsdataCr = getLastTwoDecimalDigits(
      this.state.sublegdgergroupsdata.reduce((total, row) => total + row.CrAmount, 0)
    );
    const sublegdgergroupsdataDr = getLastTwoDecimalDigits(
      this.state.sublegdgergroupsdata.reduce((total, row) => total + row.DrAmount, 0)
    );
    const sublegdgergroupsdataClosing = getLastTwoDecimalDigits(
      this.state.sublegdgergroupsdata.reduce((total, row) => total + row.Amount, 0)
    );
    
    const ledgersdataCr = getLastTwoDecimalDigits(
      this.state.ledgersdata.reduce((total, row) => total + row.CrAmount, 0)
    );
    const ledgersdataDr = getLastTwoDecimalDigits(
      this.state.ledgersdata.reduce((total, row) => total + row.DrAmount, 0)
    );
    const ledgersdataClosing = getLastTwoDecimalDigits(
      this.state.ledgersdata.reduce((total, row) => total + row.Amount, 0)
    );
    

    // Assuming the first row has column names
    const columnsLIA = [
      {
        dataField: "Name",
        text: "Name",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "CrAmount",
        text: "Cr",
        sort: true,
        footer: columnData => <div>{MaingroupsdataLiaCr}</div>,
      },
      {
        dataField: "DrAmount",
        text: "Dr",
        sort: true,
        footer: columnData => <div>{MaingroupsdataLiaDr}</div>,
      },
      {
        dataField: "Amount",
        text: "ClosingBalance",
        sort: true,
        footer: columnData => <div>{MaingroupsdataLiaClosing}</div>,
      },

      {
        dataField: "action",
        text: "View",

        formatter: (cellContent, row) => {
          return (
            <button
              type="button"
              //disabled={row.IsRefund? true : false}
              className="btn btn-danger btn-xs"
              onClick={() => this.getledgergroupsdata(row.Id, row.Name)}
            >
              View
            </button>
          );
        },
      },
    ];

    const columnsLAss = [
      {
        dataField: "Name",
        text: "Name",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "CrAmount",
        text: "Cr",
        sort: true,
        footer: columnData => <div>{MaingroupsdataAssr}</div>,
      },
      {
        dataField: "DrAmount",
        text: "Dr",
        sort: true,
        footer: columnData => <div>{MaingroupsdataAssDr}</div>,
      },
      {
        dataField: "Amount",
        text: "ClosingBalance",
        sort: true,
        footer: columnData => <div>{MaingroupsdataAssClosing}</div>,
      },

      {
        dataField: "action",
        text: "View",

        formatter: (cellContent, row) => {
          return (
            <button
              type="button"
              //disabled={row.IsRefund? true : false}
              className="btn btn-danger btn-xs"
              onClick={() => this.getledgergroupsdata(row.Id, row.Name)}
            >
              View
            </button>
          );
        },
      },
    ];

    const columns2 = [
      {
        dataField: "Name",
        text: "Name",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "CrAmount",
        text: "Cr",
        sort: true,
        footer: columnData => <div>{legdgergroupsdataCr}</div>,
      },
      {
        dataField: "DrAmount",
        text: "Dr",
        sort: true,
        footer: columnData => <div>{legdgergroupsdataDr}</div>,
      },
      {
        dataField: "Amount",
        text: "ClosingBalance",
        sort: true,
        footer: columnData => <div>{legdgergroupsdataClosing}</div>,
      },

      {
        dataField: "action",
        text: "View",

        formatter: (cellContent, row) => {
          return (
            <button
              type="button"
              //disabled={row.IsRefund? true : false}
              className="btn btn-danger btn-xs"
              onClick={() => this.getsubledgergroupsdata(row.Id, row.Name)}
            >
              View
            </button>
          );
        },
      },
    ];

    const columns3 = [
      {
        dataField: "Name",
        text: "Name",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "CrAmount",
        text: "Cr",
        sort: true,
        footer: columnData => <div>{sublegdgergroupsdataCr}</div>,
      },
      {
        dataField: "DrAmount",
        text: "Dr",
        sort: true,
        footer: columnData => <div>{sublegdgergroupsdataDr}</div>,
      },
      {
        dataField: "Amount",
        text: "ClosingBalance2",
        sort: true,
        footer: columnData => <div>{sublegdgergroupsdataClosing}</div>,
      },

      {
        dataField: "action",
        text: "View",
      
        formatter: (cellContent, row) => {
          if (!row.IsSubLedger) {
            // Create a URL with the BalanceLedger component
            const balanceLedgerUrl = `/balanceLedger/${row.Id}`; // Adjust the URL path as needed
            return (
              <button
                type="button"
                className="btn btn-info btn-xs"
                onClick={() => window.open(balanceLedgerUrl, '_blank')}
              >
                Open BalanceLedger
              </button>
            );
          } else {
            // Render the View button as before
            return (
              <button
                type="button"
                className="btn btn-danger btn-xs"
                onClick={() => this.getledgers(row.Id, row.Name)}
              >
                View
              </button>
            );
          }
        },
      }
      
    ];

    const columns4 = [
      {
        dataField: "Name",
        text: "Name",
        sort: true,
        footer: columnData => <div></div>,
      },
      {
        dataField: "CrAmount",
        text: "Cr",
        sort: true,
        footer: columnData => <div>{ledgersdataCr}</div>,
      },
      {
        dataField: "DrAmount",
        text: "Dr",
        sort: true,
        footer: columnData => <div>{ledgersdataDr}</div>,
      },
      {
        dataField: "Amount",
        text: "ClosingBalance",
        sort: true,
        footer: columnData => <div>{ledgersdataClosing}</div>,
      },
      {
        dataField: "action",
        text: "View",
      
        formatter: (cellContent, row) => {
        
            // Create a URL with the BalanceLedger component
            const balanceLedgerUrl = `/balanceLedger/${row.Id}`; // Adjust the URL path as needed
            return (
              <button
                type="button"
                className="btn btn-info btn-xs"
                onClick={() => window.open(balanceLedgerUrl, '_blank')}
              >
                Open BalanceLedger
              </button>
            );
         
         
        },
      }
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.productDataLia.length, // replace later with size(customers),
      custom: true,
    };

    // const pageOptionsCr = {
    //   sizePerPage: 10,
    //   totalSize: this.state.productDataLia.length, // replace later with size(customers),
    //   custom: true,
    // }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: this.state.productDataLia.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;
    const { selectedMulti } = this.state;
    return (
      <>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
              <Row>
                <Col lg="14">
                  <Card>
                    <CardBody>
                      <AvForm
                        className="needs-validation"
                        onValidSubmit={this.btnSave_onClick}
                      >
                        <Row>
                          <Col sm="2">
                            <AvField
                              name="ViewType"
                              label=""
                              value={
                                this.state.formData.ViewType === null
                                  ? 0
                                  : this.state.formData.ViewType
                              }
                              type="select"
                              className="form-select"
                            >
                              <option
                                value={-1}
                                defaultValue
                                label={"View Type"}
                              />
                              <option value={1} defaultValue label={"Group"} />
                              {/* <option
                                    value={2}
                                    defaultValue
                                    label={"Detail"}
                                  /> */}
                            </AvField>
                          </Col>

                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="tilldatee"
                              className="col-form-label"
                            >
                              {" "}
                              From Date
                            </label>
                          </Col>
                          <Col sm="2" className="mb-3">
                            <AvField
                              name="FromDate"
                              label=""
                              value={
                                this.state.formData.FromDate == undefined
                                  ? "2000-01-01"
                                  : this.state.formData.FromDate
                              }
                              placeholder="From Date"
                              errorMessage="Select Date "
                              validate={{ required: { value: true } }}
                              type="date"
                              className="form-control"
                            />
                          </Col>

                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="tilldatee"
                              className="col-form-label"
                            >
                              {" "}
                              To Date
                            </label>
                          </Col>
                          <Col sm="2" className="mb-3">
                            <AvField
                              name="ToDate"
                              label=""
                              value={
                                this.state.formData.FromDate == undefined
                                  ? getCurrentDate()
                                  : this.state.formData.FromDate
                              }
                              placeholder="To Date"
                              errorMessage="Select Date "
                              validate={{ required: { value: true } }}
                              type="date"
                              className="form-control"
                            />
                          </Col>

                          <Col sm="3" className="mb-3">
                            <Button
                              type="submit"
                              color="info"
                              className="mr-1 waves-effect waves-light"
                            >
                              {" "}
                              View{" "}
                            </Button>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="col-6">
                  <h2>Liabilities</h2>
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory({
                          ...pageOptions,
                          sizePerPageList: [
                            { text: "25", value: 25 },
                            { text: "50", value: 50 },
                            { text: "100", value: 100 },
                          ],
                        })}
                        keyField="id"
                        columns={columnsLIA}
                        data={this.state.productDataLia}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columnsLIA}
                            data={this.state.productDataLia}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-6">
                  <h2>Assets</h2>
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory({
                          ...pageOptions,
                          sizePerPageList: [
                            { text: "25", value: 25 },
                            { text: "50", value: 50 },
                            { text: "100", value: 100 },
                          ],
                        })}
                        keyField="id"
                        columns={columnsLAss}
                        data={this.state.productDataAssest}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columnsLAss}
                            data={this.state.productDataAssest}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Modal
                isOpen={this.state.openledgergroups}
                toggle={() => this.setState({ openledgergroups: false })}
                scrollable={true}
                backdrop={"static"}
                id="staticBackdrop2"
                className="modal-fullscreen"
              >
                <div className="modal-header">
                  <h5 className="modal-title">{this.state.BalanceSheetName}</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => this.setState({ openledgergroups: false })}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col className="col-6">
                      <Card>
                        <CardBody>
                          <PaginationProvider
                            pagination={paginationFactory({
                              ...pageOptions,
                              sizePerPageList: [
                                { text: "25", value: 25 },
                                { text: "50", value: 50 },
                                { text: "100", value: 100 },
                              ],
                            })}
                            keyField="id"
                            columns={columns2}
                            data={this.state.legdgergroupsdata}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={columns2}
                                data={this.state.legdgergroupsdata}
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col md="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar
                                              {...toolkitProps.searchProps}
                                            />
                                            <Button
                                              type="button"
                                              color="primary"
                                              onClick={() =>
                                                this.setState({
                                                  openledgergroups: false,
                                                })
                                              }
                                              className="mr-1 waves-effect waves-light"
                                            >
                                              {" "}
                                              Close{" "}
                                            </Button>
                                            <Button
                                              type="button"
                                              color="primary"
                                              onClick={() =>
                                                this.exportToExcel(1)
                                              }
                                              className="mr-1 waves-effect waves-light"
                                            >
                                              {" "}
                                              Excel{" "}
                                            </Button>

                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            // selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => this.setState({ openledgergroups: false })}
                    className="mr-1 waves-effect waves-light"
                  >
                    {" "}
                    Close{" "}
                  </Button>
                </div>
              </Modal>

              <Modal
                isOpen={this.state.opensubledgergroups}
                toggle={() => this.setState({ opensubledgergroups: false })}
                scrollable={true}
                backdrop={"static"}
                id="staticBackdrop2"
                className="modal-fullscreen"
              >
                <div className="modal-header">
                  <h5 className="modal-title">{this.state.LedgerGroupName}</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() =>
                      this.setState({ opensubledgergroups: false })
                    }
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col className="col-6">
                      <Card>
                        <CardBody>
                          <PaginationProvider
                            pagination={paginationFactory({
                              ...pageOptions,
                              sizePerPageList: [
                                { text: "25", value: 25 },
                                { text: "50", value: 50 },
                                { text: "100", value: 100 },
                              ],
                            })}
                            keyField="id"
                            columns={columns3}
                            data={this.state.sublegdgergroupsdata}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={columns3}
                                data={this.state.sublegdgergroupsdata}
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col md="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar
                                              {...toolkitProps.searchProps}
                                            />
                                            <Button
                                              type="button"
                                              color="primary"
                                              onClick={() =>
                                                this.setState({
                                                  opensubledgergroups: false,
                                                })
                                              }
                                              className="mr-1 waves-effect waves-light"
                                            >
                                              {" "}
                                              Close{" "}
                                            </Button>
                                            <Button
                                              type="button"
                                              color="primary"
                                              onClick={() =>
                                                this.exportToExcel(2)
                                              }
                                              className="mr-1 waves-effect waves-light"
                                            >
                                              {" "}
                                              Excel{" "}
                                            </Button>
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            // selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <Button
                    type="button"
                    color="primary"
                    onClick={() =>
                      this.setState({ opensubledgergroups: false })
                    }
                    className="mr-1 waves-effect waves-light"
                  >
                    {" "}
                    Close{" "}
                  </Button>
                </div>
              </Modal>

              <Modal
                isOpen={this.state.openledgers}
                toggle={() => this.setState({ openledgers: false })}
                scrollable={true}
                backdrop={"static"}
                id="staticBackdrop2"
                className="modal-fullscreen"
              >
                <div className="modal-header">
                  <h5 className="modal-title">{this.state.SubGroupName}</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => this.setState({ openledgers: false })}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col className="col-6">
                      <Card>
                        <CardBody>
                          <PaginationProvider
                            pagination={paginationFactory({
                              ...pageOptions,
                              sizePerPageList: [
                                { text: "25", value: 25 },
                                { text: "50", value: 50 },
                                { text: "100", value: 100 },
                              ],
                            })}
                            keyField="id"
                            columns={columns4}
                            data={this.state.ledgersdata}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={columns4}
                                data={this.state.ledgersdata}
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col md="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar
                                              {...toolkitProps.searchProps}
                                            />
                                            <Button
                                              type="button"
                                              color="primary"
                                              onClick={() =>
                                                this.setState({
                                                  openledgers: false,
                                                })
                                              }
                                              className="mr-1 waves-effect waves-light"
                                            >
                                              {" "}
                                              Close{" "}
                                            </Button>
                                            <Button
                                              type="button"
                                              color="primary"
                                              onClick={() =>
                                                this.exportToExcel(3)
                                              }
                                              className="mr-1 waves-effect waves-light"
                                            >
                                              {" "}
                                              Excel{" "}
                                            </Button>
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            // selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => this.setState({ openledgers: false })}
                    className="mr-1 waves-effect waves-light"
                  >
                    {" "}
                    Close{" "}
                  </Button>
                </div>
              </Modal>
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(reports_BalanceSheet);
